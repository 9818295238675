import { useCallback, useRef, useState } from 'react';
import Web3 from 'web3';
import { v4 as uuid } from 'uuid';
import { BigNumber } from 'bignumber.js';
import { getErrorMessage } from 'common/utils/sdk';
import { Modes } from 'uikit/MnemonicGenerator/MnemonicGeneratorUi/types';
import BlockchainConnector, { ResourceFile, WorkflowPropsValues } from 'connectors/sdk/BlockchainConnector';
import { useAppDispatch } from 'lib/hooks';
import { trackEvent } from 'lib/features/events/thunks';
import { Form, Steps } from 'lib/features/createOrder/types';
import { getExternalId, getParsedErrorTransactions } from 'common/utils';
import { getOfferFormIds } from 'lib/features/createOrder/helpers';
import { useFileUploader } from './useFileUploader';
import { useGenerateTII } from './useGenerateTII';
import { useEncryptFile } from './useEncryptFile';
import { useWorkflowSubscription, OnWorkflowCreated } from './useWorkflowSubscription';
import { ChangeStateProps, UseWorkflowProcessResult } from './useWorkflowProcess';

export enum ProcessType {
  FILE = 'FILE',
  APPROVE = 'APPROVE',
  WORKFLOW = 'WORKFLOW'
}

export enum ProcessStatus {
  QUEUE = 'QUEUE',
  PROGRESS = 'PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR',
  QUEUE_HIDE = 'QUEUE_HIDE',
  DONE_HIDE = 'DONE_HIDE',
}

export interface Process {
  [process: string]: {
    status: ProcessStatus;
    error?: Error | string;
  };
}

export interface Files {
  [id: string]: File;
}

export interface RunWorkflowProps {
    form: Form;
    web3?: Web3 | null;
    state?: Process;
    files?: Files;
}

export interface UseWorkflowResult {
    runWorkflow: (props: RunWorkflowProps) => Promise<string | void>;
    uploading: boolean;
    generating: boolean;
    encrypting: boolean;
    progress: number;
    changeStateProcess: (props: ChangeStateProps) => void;
    stateProcess: Process;
    loading: boolean;
    workflowId?: string;
}

export const getWorkflowValues = async (
  form: Form,
  mnemonic: string,
  teeExternalId: string,
): Promise<WorkflowPropsValues> => {
  const {
    solution: solutionData,
    tee: teeData,
    storage: storageData,
    data: dataData,
    deposit,
  } = form[Steps.BUILD_ORDER];
  const solution = [{ value: solutionData?.value, slots: solutionData?.slots }]
    .concat((solutionData?.base || []).map((item) => ({ value: item.value, slots: item?.slots })));
  const storage = {
    value: storageData?.value,
    slots: storageData?.slots,
  };
  const tee = {
    value: teeData?.value,
    slots: teeData?.slots,
  };

  const data = getOfferFormIds(dataData).map(
    (value) => ({ value, slots: dataData.find((item) => item.value === value)?.slots }),
  );
  return {
    mnemonic: mnemonic ? mnemonic.trim() : '',
    solution,
    data,
    tee,
    storage,
    deposit,
    teeExternalId,
  };
};

export const getProcessList = (form: Form): ProcessType[] => {
  const { tee, file } = form?.[Steps.BUILD_ORDER] || {};
  return ([] as ProcessType[])
    .concat((tee ? [ProcessType.WORKFLOW, ProcessType.WORKFLOW] : []))
    .concat(file ? ProcessType.FILE : []);
};

const isAllowGood = async (actionAccountAddress: string, deposit: string) => {
  const allow = await BlockchainConnector.getInstance().getAllowance(actionAccountAddress);
  if (new BigNumber(allow).isLessThan(new BigNumber(deposit))) {
    return false;
  }
  return true;
};

interface WorkflowApproveProps {
  actionAccountAddress: string;
  deposit: string;
  changeState: (props: ChangeStateProps) => void;
  web3: Web3;
}

export const workflowApprove = async (props: WorkflowApproveProps) => {
  const {
    actionAccountAddress,
    deposit,
    web3,
    changeState = () => {},
  } = props || {};
  if (!actionAccountAddress) throw new Error('Account address required for approval');
  if (!web3) throw new Error('Web3 instance required for approval');
  try {
    let isAllow = await isAllowGood(actionAccountAddress, deposit);
    let approved = false;
    while (!isAllow) {
      changeState({ process: ProcessType.APPROVE, status: ProcessStatus.PROGRESS });
      await BlockchainConnector.getInstance().approveWorkflow({ web3, actionAccountAddress });
      approved = true;
      isAllow = await isAllowGood(actionAccountAddress, deposit);
    }
    if (approved) {
      changeState({ process: ProcessType.APPROVE, status: ProcessStatus.DONE });
    } else {
      changeState({ process: ProcessType.APPROVE, status: ProcessStatus.DONE_HIDE });
    }
  } catch (e) {
    changeState({ process: ProcessType.APPROVE, status: ProcessStatus.ERROR, error: e as Error });
    throw e;
  }
};

export const useWorkflow = (
  actionAccountAddress: string | undefined,
  useWorkflowProcess: () => UseWorkflowProcessResult,
): UseWorkflowResult => {
  const dispatch = useAppDispatch();
  const teeExternalIdRef = useRef(getExternalId());
  const [workflowId, setWorkflowId] = useState<string | undefined>();
  const { uploading, uploadFileStream } = useFileUploader();
  const { generating, generateByOffer } = useGenerateTII();
  const { encrypting, encryptFile } = useEncryptFile();
  const onCreateWorkflow = useCallback((data: OnWorkflowCreated) => {
    const { orderId } = data?.data?.workflowCreated || {};
    setWorkflowId(orderId);
  }, []);
  useWorkflowSubscription(actionAccountAddress, teeExternalIdRef.current, onCreateWorkflow);

  const {
    loading,
    onChangeLoading,
    progress,
    changeState,
    state: stateProcess,
    init: initProcess,
    rerunNotDone,
  } = useWorkflowProcess();
  const runWorkflow = useCallback(
    async (props: RunWorkflowProps): Promise<string | void> => {
      onChangeLoading(true);
      try {
        const {
          form,
          web3,
          files,
        } = props || {};
        if (!actionAccountAddress || !web3) throw new Error('Metamask account not found');
        const { tee, file: fileName } = form?.[Steps.BUILD_ORDER] || {};
        const file = files?.[fileName as string];
        const { phraseGenerated, phraseInput, phraseMode } = form?.[Steps.CREATE_PASSPHRASE] || {};
        let orderId: string | void = '';
        const phrase = phraseMode === Modes.generate ? phraseGenerated : phraseInput;
        let tiiGeneratorId;
        if (!phrase) throw new Error('Seed phrase required');
        if (!Object.keys(stateProcess).length) {
          initProcess(getProcessList(form));
        } else {
          rerunNotDone();
        }
        const workflowValues = await getWorkflowValues(form, phrase, teeExternalIdRef.current);
        if (!workflowValues?.deposit) throw new Error('Deposit is not defined');
        const resources: ResourceFile[] = [];
        if (file && stateProcess[ProcessType.FILE]?.status !== ProcessStatus.DONE) {
          const fileWithExtension = file.name.split('.');
          if (fileWithExtension?.length < 2) throw new Error('File extension is not defined');
          if (!tee?.value) throw new Error('TEE required');
          try {
            changeState({ process: ProcessType.FILE, status: ProcessStatus.PROGRESS });
            const {
              encryptedStream, encryption, getAuthTag, getHash,
            } = await encryptFile(file);
            const extension = fileWithExtension.slice(1).join('.');
            const fileName = `${uuid()}.${extension}.encrypted`;
            const { mac, hash } = await uploadFileStream({
              stream: encryptedStream, fileName, getAuthTag, getHash,
            });
            if (hash) {
              resources.push({ hash, type: 'Data' });
            }
            tiiGeneratorId = await generateByOffer({
              offerId: tee?.value,
              encryption: {
                ...encryption,
                mac: Buffer.from(mac).toString(encryption.encoding),
              },
              filepath: fileName,
              inputOffers: ((workflowValues.solution || [])).map(({ value }) => value as string),
              resources,
            });
            changeState({ process: ProcessType.FILE, status: ProcessStatus.DONE });
          } catch (e) {
            changeState({ process: ProcessType.FILE, status: ProcessStatus.ERROR, error: e as Error });
            throw e;
          }
        }
        await workflowApprove({
          actionAccountAddress,
          web3,
          deposit: workflowValues?.deposit,
          changeState,
        });
        if (stateProcess[ProcessType.WORKFLOW]?.status !== ProcessStatus.DONE) {
          try {
            changeState({ process: ProcessType.WORKFLOW, status: ProcessStatus.PROGRESS });
            orderId = await BlockchainConnector.getInstance().workflow({
              values: {
                ...workflowValues,
                args: tiiGeneratorId ? JSON.stringify({ data: [tiiGeneratorId] }) : undefined,
                resources,
              },
              actionAccountAddress,
              web3,
            });
            changeState({ process: ProcessType.WORKFLOW, status: ProcessStatus.DONE });
            dispatch(trackEvent({ eventType: 'order_creation_transaction_confirmed', property: { result: 'success' } }));
          } catch (e) {
            changeState({ process: ProcessType.WORKFLOW, status: ProcessStatus.ERROR, error: e as Error });
            dispatch(
              trackEvent({
                eventType: 'order_creation_transaction_confirmed',
                property: { result: 'error', error: (e as Error)?.message, errorStack: (e as Error)?.stack },
              }),
            );
            throw e;
          }
        }
        return orderId;
      } catch (e) {
        const parsedError = getParsedErrorTransactions(e as Error);
        dispatch(trackEvent({
          eventType: 'order_form_create',
          property: {
            result: 'error',
            error: getErrorMessage(e as Error) ?? undefined,
            errorStack: (e as Error)?.stack,
            ...(parsedError.transactionHash ? { txHash: parsedError.transactionHash } : {}),
          },
        }));
        throw e;
      } finally {
        onChangeLoading(false);
      }
    },
    [
      encryptFile,
      generateByOffer,
      changeState,
      initProcess,
      stateProcess,
      rerunNotDone,
      onChangeLoading,
      actionAccountAddress,
      dispatch,
      uploadFileStream,
    ],
  );
  return {
    runWorkflow,
    uploading,
    generating,
    encrypting,
    progress,
    changeStateProcess: changeState,
    stateProcess,
    loading,
    workflowId,
  };
};
